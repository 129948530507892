import request from "superagent";
import { authProvider } from "../../authProvider";
import { baseURL } from "../constants";
import { loadCompanies } from "../../views/Coaching/actions/companies";
import { loadCompany } from "../../views/Management/actions/company";
import { getMngtData } from "../../views/Management/actions/loggedInMngtData";
import { getMentorData } from "../../views/Coaching/actions/loggedInMentorData";
import { getAccessToken } from "../commonFunctions";

let accounts;
// fetching the roles
export const ROLES_FETCHED = "ROLES_FETCHED";

const rolesFetched = (roles) => ({
  type: ROLES_FETCHED,
  payload: roles,
});

export const getRoles = () => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken if null
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(`${baseURL}/portal/Roles/GetRoles?includeCompanyIds=true`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res && res.unauthorized) {
        console.log(`401 error, retry roles API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(rolesFetched(response.body));

      const loggedInCoach =
        response.body.map((role) => role.roleString).includes("sdinsights") ||
        response.body.map((role) => role.roleString).includes("coaching");
      if (loggedInCoach) {
        dispatch(loadCompanies()); //moved it to loadUnreadCount action to fetch companies after fetching unread count
        //fetch unread count for companies
        //dispatch(loadUnreadCount())

        const loggedInMentor = response.body
          .map((role) => role.roleString)
          .includes("mentor") || response.body.map((role) => role.roleString).includes("coach2coach");
        //mentor=> Role created for Bentveltzen, coach2coach=> Role created for K + N C2C portal/location
        //Both roles specify ext coach/mentor
        if (loggedInMentor) {
          // external coach/mentor is logged in
          const companyIdAccesses =
            response.body.length > 0
              ? response.body.filter((role) => role.companyId)
              : null;

          dispatch(getMentorData(companyIdAccesses[0].companyId));
        }
      } else {
        // mngt is logged in
        const loggedInCompanyIds =
          response.body.length > 0
            ? response.body.filter((role) => role.companyId)
            : null;
        if (loggedInCompanyIds) {
          if (loggedInCompanyIds.length === 1) {
            dispatch(loadCompany(loggedInCompanyIds[0].companyId));
            dispatch(getMngtData(loggedInCompanyIds[0].companyId));
          }
        }

      }
    })
    .catch(console.error);
};
