import { combineReducers } from "redux";
import companies from "../../views/Coaching/reducers/companies";
import compNavi from "../../views/Coaching/reducers/compNavi";
import coachNavi from "../../views/Coaching/reducers/coachNavi";
import auth from "./auth";
import aadResponse from "./aadResponse";
import drivers from "../../views/Coaching/reducers/drivers";
import driver from "../../views/Coaching/reducers/driver";
import driverPrizesTable from "../../views/Management/reducers/driverPrizesTable";
import driversRequestedPrizesTable from "../../views/Management/reducers/driversRequestedPrizesTable";
import undoable from "../../views/Coaching/reducers/undoable";
import scores from "../../views/Coaching/reducers/scores";
import scoreHistory from "../../views/Coaching/reducers/scoreHistory";
import roles from "./roles";
import notes from "../../views/Coaching/reducers/notes";
import driversTable from "../../views/Management/reducers/driversTable";
import mngNavi from "../../views/Management/reducers/mngNavi";
import outlierScores from "../../views/Coaching/reducers/outlierScores";
import inactiveDriversTable from "../../views/Management/reducers/inactiveDriversTable";
import companyGroups from "../../views/Management/reducers/companyGroups";
import groupSettings from "../../views/Management/reducers/groupSettings";
import portalUsers from "../../views/Management/reducers/portalUsers";
import driverRank from "../../views/Coaching/reducers/driverRank";
import driverStats from "../../views/Coaching/reducers/driverStats";
import loggedInDate from "../../views/Coaching/reducers/loggedInDate";
import scoreProgress from "../../views/Coaching/reducers/scoreProgress";
import visUrls from "../../views/Management/reducers/visUrls";
import driverStatsHistory from "../../views/Coaching/reducers/driverStatsHistory";
import driverStatsHistoryDate from "../../views/Coaching/reducers/driverStatsHistoryDate";
import messages from "../../views/Coaching/reducers/messages";
import draftMessages from "../../views/Coaching/reducers/draftMessages";
import messagesLoading from "../../views/Coaching/reducers/messagesLoading";
import loggedInMngtData from "../../views/Management/reducers/loggedInMngtData";
import driversTableIsLoading from "../../views/Management/reducers/driversTableIsLoading";
import messageTemplates from "../../views/Coaching/reducers/messageTemplates";
import driverLoading from "../../views/Coaching/reducers/driverLoading";
import loggedInMentorData from "../../views/Coaching/reducers/loggedInMentorData";
import featuresAvailable from "../../views/Management/reducers/featuresAvailable";
import groupsForPrizes from "../../views/Management/reducers/groupsForPrizes";
import achievements from "../../views/Management/reducers/achievements";
import loyaltyActivated from "../../views/Management/reducers/loyaltyActivated";
import companyLocationData from "../../views/Coaching/reducers/companyLocationData";
// import coachMessage from "../../views/Coaching/reducers/coachMessage";
import companyGroupsCoaching from "../../views/Coaching/reducers/companyGroups";
import graphs from "../../views/Management/reducers/graphs";
import driverCreditsData from "../../views/Management/reducers/driverCreditsTable";
import graphsForDashboard from "../../views/Coaching/reducers/dashboardGraphs";
import mngWithDriversNaviState from "../../views/Management/ManagementWithScores/reducers/driversForSidebar";
import driverInManagement from "../../views/Management/ManagementWithScores/reducers/driverData";
import driverLoadingInMng from "../../views/Management/ManagementWithScores/reducers/driverLoading";
import coachInfo from "./coach_language";
import dailyScores from "../../views/Coaching/reducers/driverDailyScores";
import coachingCompanyFeatures from "../../views/Coaching/reducers/companyFeatures";
import dailyStats from "../../views/Coaching/reducers/driverDailyStats";
import weeklyScoreHistory from "../../views/Coaching/reducers/weeklyScoreHistory";
import messageTranslation from "../../views/Coaching/reducers/messageTranslation";
import weeklyStats from "../../views/Coaching/reducers/driverWeeklyStats";
import monthlyScores from "../../views/Coaching/reducers/driverMonthlyScores";
import monthlyStats from "../../views/Coaching/reducers/driverMonthlyStats";
import minGoalGraph from "../../views/Coaching/reducers/minGoalGraph";
import scoreDistributionGraph from "../../views/Coaching/reducers/scoreDistributionGraph";
import allScoresGraph from "../../views/Coaching/reducers/allScoresGraph";
import allStatsGraph from "../../views/Coaching/reducers/allStatsGraph";
import sdIssues from "../../views/Coaching/reducers/serviceDeskIssue";
import behaviors from "../../views/Management/reducers/behaviors";
import behaviorsVsMonths from "../../views/Management/reducers/behavVsMonthsTable";
import driverVsMonths from "../../views/Management/reducers/driverVsMonthsTable";
import driverVsScores from "../../views/Management/reducers/driverVsScoresTable";
import scoreOverviewLoadingState from "../../views/Management/reducers/scoreOverviewLoadingState";
import tachoDataLoadingState from "../../views/Coaching/reducers/tachoLoadingState";
import companyBehaviors from "../../views/Coaching/reducers/companyBehaviors";
import tachoData from "../../views/Coaching/reducers/tachoData";
import tachoManagementData from "../../views/Management/reducers/tachoManagementData";
import driverProfile from "../../views/Management/reducers/driverProfileReducer";
import driverProfileForCoaching from "../../views/Coaching/reducers/driverProfileReducer";
import driverProfileLoading from "../../views/Management/reducers/driverProfileLoading";
import companyManagementThresholds from "../../views/Management/reducers/companyManagementThresholds";
import companyThresholds from "../../views/Coaching/reducers/companyThresholds";
import coachAssist from "../../views/Coaching/reducers/coachAssist";


// import driverStatsMng from "../../views/Management/ManagementWithScores/reducers/driverStatsData";
// import outlierScoresMng from "../../views/Management/ManagementWithScores/reducers/outlierScoresMng";

const undoableCoachNavi = undoable(coachNavi);
const undoableScores = undoable(scores);

export default combineReducers({
  companies,
  companyGroupsCoaching,
  companyLocationData,
  compNavi,
  coachInfo,
  auth,
  aadResponse,
  drivers,
  driver,
  driverPrizesTable,
  driversRequestedPrizesTable,
  driverLoading,
  undoableCoachNavi,
  undoableScores,
  scoreHistory,
  roles,
  notes,
  driversTable,
  mngNavi,
  outlierScores,
  inactiveDriversTable,
  companyGroups,
  portalUsers,
  driverRank,
  driverStats,
  loggedInDate,
  scoreProgress,
  visUrls,
  driverStatsHistory,
  driverStatsHistoryDate,
  messages,
  // coachMessage,
  messagesLoading,
  loggedInMngtData,
  driversTableIsLoading,
  messageTemplates,
  loggedInMentorData,
  featuresAvailable,
  groupsForPrizes,
  achievements,
  loyaltyActivated,
  groupSettings,
  graphs,
  driverCreditsData,
  graphsForDashboard,
  mngWithDriversNaviState,
  driverInManagement,
  driverLoadingInMng,
  dailyScores,
  coachingCompanyFeatures,
  dailyStats,
  weeklyScoreHistory,
  messageTranslation,
  weeklyStats,
  monthlyScores,
  monthlyStats,
  minGoalGraph,
  scoreDistributionGraph,
  allScoresGraph,
  allStatsGraph,
  sdIssues,
  behaviors,
  behaviorsVsMonths,
  driverVsMonths,
  driverVsScores,
  scoreOverviewLoadingState,
  companyBehaviors,
  tachoData,
  tachoManagementData,
  tachoDataLoadingState,
  driverProfile,
  driverProfileForCoaching,
  companyThresholds,
  companyManagementThresholds,
  driverProfileLoading,
  coachAssist,
  draftMessages
});
