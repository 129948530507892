import {
  DRAFT_MESSAGES_FETCHED,
  DRAFT_MESSAGES_CLEARED
} from "../actions/messages";



let savedMessage;

let draftMessage = [];


export default (state = null, action = {}) => {
  switch (action.type) {
    case DRAFT_MESSAGES_FETCHED:
      savedMessage = {
        draftId: action.payload.draftId,
        message: action.payload.messageText,
        driverId: action.payload.driverId ? action.payload.driverId : 0
      };
      draftMessage = savedMessage;
      return {
        ...state,
        savedMessages: draftMessage
      };
      case DRAFT_MESSAGES_CLEARED:
      savedMessage = {
        driverId: action.payload
      };
      draftMessage = savedMessage;
      return {
        ...state,
        savedMessages: draftMessage
      };
    default:
      return state;
  }
};
