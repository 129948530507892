import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
// import i18next from "i18next";

let accounts;

export const AI_TEXT_LOADING_TOGGLE="AI_TEXT_LOADING_TOGGLE";

export const AI_TEXT_FETCHED="AI_TEXT_FETCHED";
export const AI_TEXT_CLEARED="AI_TEXT_CLEARED";

const toggleAiTextLoading=()=>({
    type:AI_TEXT_LOADING_TOGGLE
})

export const clearAiTextLoaded=()=>({
    type:AI_TEXT_CLEARED
})

const aiTextLoaded=(text)=>(
    {
        type:AI_TEXT_FETCHED,
        payload:text
    }
)

export const getCoachFeedbackScoresShortTerm =
    ({driverId,lang}) => async (dispatch, getState) => {
        const store = getState();
        let { auth} = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(clearAiTextLoaded());
        dispatch(toggleAiTextLoading());
request
    .get(`${baseURL}/portal/coaching/rag/GetCoachFeedbackScoresShortTerm?driverId=${driverId}&CoachLanguageCode=${lang}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
       dispatch(aiTextLoaded(response.body?.response ?response.body?.response:"" ));
       dispatch(toggleAiTextLoading())
    })
    .catch(console.error);
  };

  export const getCoachFeedbackScoresLongTerm =
    ({driverId,lang}) => async (dispatch, getState) => {
        const store = getState();
        let { auth} = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(clearAiTextLoaded());
        dispatch(toggleAiTextLoading())
request
    .get(`${baseURL}/portal/coaching/rag/GetCoachFeedbackScoresLongTerm?driverId=${driverId}&CoachLanguageCode=${lang}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
       dispatch(aiTextLoaded(response.body?.response ?response.body?.response:""));
       dispatch(toggleAiTextLoading())
    })
    .catch(console.error);
  };

  export const getCoachFeedbackStats =
    ({driverId,lang}) => async (dispatch, getState) => {
        const store = getState();
        let { auth} = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(clearAiTextLoaded());
        dispatch(toggleAiTextLoading())
request
    .get(`${baseURL}/portal/coaching/rag/GetCoachFeedbackStats?driverId=${driverId}&CoachLanguageCode=${lang}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
       dispatch(aiTextLoaded(response.body?.response ?response.body?.response:""));
       dispatch(toggleAiTextLoading())
    })
    .catch(console.error);
  };

  export const getCoachFeedbackMessage =
    ({driverId,lang}) => async (dispatch, getState) => {
        const store = getState();
        let { auth} = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(clearAiTextLoaded());
        dispatch(toggleAiTextLoading())
request
    .get(`${baseURL}/portal/coaching/rag/GetCoachFeedbackMessage?driverId=${driverId}&CoachLanguageCode=${lang}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
       dispatch(aiTextLoaded(response.body?.response ?response.body?.response:""));
       dispatch(toggleAiTextLoading())
    })
    .catch(console.error);
  };

  export const getDriverScoreGroupComparison =
    ({driverId,lang}) => async (dispatch, getState) => {
        const store = getState();
        let { auth} = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(clearAiTextLoaded());
        dispatch(toggleAiTextLoading())
request
    .get(`${baseURL}/portal/coaching/rag/GetDriverScoreGroupComparison?driverId=${driverId}&CoachLanguageCode=${lang}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
       dispatch(aiTextLoaded(response.body?.response ?response.body?.response:""));
       dispatch(toggleAiTextLoading())
    })
    .catch(console.error);
  };

  export const getDriverStatGroupComparison =
    ({driverId,lang}) => async (dispatch, getState) => {
        const store = getState();
        let { auth} = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(clearAiTextLoaded());
        dispatch(toggleAiTextLoading())
request
    .get(`${baseURL}/portal/coaching/rag/GetDriverStatGroupComparison?driverId=${driverId}&CoachLanguageCode=${lang}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
       dispatch(aiTextLoaded(response.body?.response ?response.body?.response:""));
       dispatch(toggleAiTextLoading())
    })
    .catch(console.error);
  };